<template>
  <div class="min-h-screen bg-center bg-cover bg-space flex items-center justify-center">
    <div class="text-white font-mono text-lg font-bold md:w-3/5 text-justify p-10 md:border-2 border-white rounded-md bg-black bg-opacity-30 flex flex-col items-center">
      <p>
        My current carreer goal is to work in the space industry as an embedded software engineer. 
        I have been passionate about space for a long time and it's one of the reasons why I chose to study in Toulouse, which is the heart of the space industry in France.
      </p>
      <br/>
      <p>
        In November, I met a systems engineer working at Airbus OneWeb Satellites. I learned a lot about the different career paths I can chose and the different jobs involved in designing satellite systems.
      </p>
      <br/>
      <img src="myjobglasses.PNG" class="center">
    </div>
  </div>
</template>

<script>

export default {
  name: 'CareerDevelopment',
}
</script>
